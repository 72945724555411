<template>
  <div class="goods-receipt">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6 mb-12">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click.prevent="$router.back()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>ใบเสร็จ</h1>
              </div>
            </div>
          </v-app-bar>

          <v-container fluid>
            <h2 class="header-receive">
              หมายเลขคำสั่งซื้อ :
              {{ this.$store.getters.getDetailsReceive.orderNo }}
            </h2>
            <h4 class="sub-header-receive">
              วันที่สั่งซื้อ :
              {{
                this.$store.getters.getDetailsReceive.createDate | dateFormat
              }}
            </h4>
            <v-divider class="mt-2 mb-4"></v-divider>
            <div>
              <v-expansion-panels focusable>
                <v-expansion-panel
                  v-for="(item, i) in allOrderPR"
                  :key="i"
                  :disabled="item.purchaseRequisitionStatus == 'DRAFT'"
                >
                  <v-expansion-panel-header>{{
                    item.purchaseOrderResponse.purchaseNo
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <div class="text-center mt-2" v-if="allGr.length == 0">
                          <span style="color: red;">ยังไม่มีใบเสร็จ</span>
                      </div>
                      <div class="pa-2" v-for="(data,i) in allGr" :key="i">
                          <div v-if="item.purchaseOrderResponse.id == data.purchaseOrderId">
                              <div v-for="(doc,index) in data.documents" :key="index">
                                <!-- <a :href="doc.documentPath">ดาวน์โหลดเอกสาร</a>  -->
                                <a :href="doc.documentPath">
                                  <v-img
                                  :src="doc.documentPath"
                                  :aspect-ratio="4 / 3"
                                  style="width: 100px;"
                                >
                                </v-img>
                                </a>
                              </div>
                              <v-divider></v-divider>
                          </div>
                      </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "GoodsReceipt",
  data() {
    return {
      allOrderPR: [],
      allGr: []
    };
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm:ss a.");
    },
  },
  created() {
    let purchaseId = [
      ...new Set(
        this.$store.getters.getDetailsReceive.details.map(
          (item) => item.purchaseId
        )
      ),
    ];
    // let purchaseId = ['64','63']
    for (let index = 0; index < purchaseId.length; index++) {
      axios.get(`/orders/pr/${purchaseId[index]}`).then((res) => {
        if (res.data.purchaseOrderResponse !== null) {
          this.allOrderPR.push(res.data);
          console.log(res.data);
          let prID = res.data.purchaseOrderResponse.id;
          axios.get(`/orders/goods-receipt/line/U690e93ea9c5bdc0d105896b8b8f0da47`)
            .then((result) => {
              console.log(result);
              result.data.forEach((poId) => {
                if (prID == poId.purchaseOrderId) {
                  console.log(poId);
                  this.allGr.push(poId)
                }
              });
            });
        }
        //console.log(res.data.purchaseOrderResponse.id);
      });
    }
    console.log(this.allOrderPR);
  },
  //   mounted() {
  //        axios.get(`${api}/orders/goods-receipt/line/U690e93ea9c5bdc0d105896b8b8f0da47`)
  //       .then(result => {
  //         console.log(result);
  //       })
  //   }
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
.header-receive {
  font-weight: 500;
  font-size: 18px;
  color: #292929;
}
.sub-header-receive {
  font-weight: 400;
  font-size: 14px;
  color: #343434;
}
</style>